const $ = require('jquery');
import validate from 'jquery-validation';

$(function(){

'use strict';

/*******************************************
 * 
 * validation
 * 
********************************************/

(function() {

	// element
	var $elm_form = $('form[data-target=contact]');
	
	// var

	// init
	function init() {
		$elm_form.validate({
			rules : {
				email : { required: true, email: true },
                email_confirm : { required: true, email: true, equalTo: "[name=email]" },
                agree : {
                    required: true
                },
			},
            messages: {
                agree : {
                    required: "個人情報の取り扱いをご確認ください。"
                },
            },
			errorElement : "error",
			errorClass: "error",
		    errorPlacement: function(error, element){
				if (element.attr("name") == "agree") {
                    error.insertAfter("#error_agree");
				}else if(element.is("select")){
		            error.appendTo( element.parent().parent() );
		        }else if(element.is("input[type='radio'")){
		            error.appendTo( element.parent().parent().parent().parent() );
		        }else if(element.is("input[type='checkbox'")){
		            error.appendTo( element.parent().parent().parent().parent() );
		        }else{
		            // This is the default behavior 
		            error.insertAfter( element );
		        }
		    },
			highlight: function(element, errorClass, validClass) {
		        if($(element).is("input[type='radio'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.addClass(errorClass).removeClass(validClass);
		        }else if($(element).is("input[type='checkbox'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.addClass(errorClass).removeClass(validClass);
		        }else{
		            // This is the default behavior 
					$(element).addClass(errorClass).removeClass(validClass);
		        }
			},
			unhighlight: function(element, errorClass, validClass) {
		        if($(element).is("input[type='radio'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.removeClass(errorClass).addClass(validClass);
		        }else if($(element).is("input[type='checkbox'")){
					var $elm = $(element).parent().parent().parent().parent().find('._box');
					$elm.removeClass(errorClass).addClass(validClass);
		        }else{
		            // This is the default behavior 
					$(element).removeClass(errorClass).addClass(validClass);
		        }
			}
		});
		
	}

	function func() {
		
	}

	init();

})();

/*******************************************
 * 
 * form error
 * 
********************************************/

(function() {

	// element

	// var

	// init
	function init() {

        /*
		 * Translated default messages for the jQuery validation plugin.
		 * Locale: JA (Japanese; 日本語)
		 */
        $.extend($.validator.messages, {
            required: "この項目は必須です。",
            remote: "この項目を修正してください。",
            email: "有効なEメールアドレスを入力してください。",
            url: "有効なURLを入力してください。",
            date: "有効な日付を入力してください。",
            dateISO: "有効な日付（ISO）を入力してください。",
            number: "有効な数字を入力してください。",
            digits: "数字のみを入力してください。",
            creditcard: "有効なクレジットカード番号を入力してください。",
            equalTo: "同じ値をもう一度入力してください。",
            extension: "有効な拡張子を含む値を入力してください。",
            maxlength: $.validator.format("{0} 文字以内で入力してください。"),
            minlength: $.validator.format("{0} 文字以上で入力してください。"),
            rangelength: $.validator.format("{0} 文字から {1} 文字までの値を入力してください。"),
            range: $.validator.format("{0} から {1} までの値を入力してください。"),
            max: $.validator.format("{0} 以下の値を入力してください。"),
            min: $.validator.format("{0} 以上の値を入力してください。")
        });


    }

	function func() {
		
	}

	init();

})();

/*******************************************
 *
 * form button
 *
********************************************/

(function() {

	// element
	var $elm_form = $('form[data-target=contact]');
	var $elm_button_confirm = $elm_form.find('[data-target=form_confirm]');
	var $elm_button_back = $elm_form.find('[data-target=form_back]');
	var $elm_button_submit = $elm_form.find('[data-target=form_submit]');

	// var

	// init
	function init() {

		// $elm_button_confirm.on('click', function (event) {
		// 	event.preventDefault();
		// 	$elm_form.submit();
		// });
		$elm_button_back.on('click', function (event) {
			event.preventDefault();
			$elm_form.attr('action', './');
		    $elm_form.submit();
		});
		// $elm_button_submit.on('click', function (event) {
		// 	event.preventDefault();
		//     $elm_form.submit();
		// });

	}

	function func() {

	}

	init();

})();

/*******************************************
 * 
 * form test
 * 
********************************************/

(function() {

	// element
	var $elm_button = $('[data-target=form_test]');

	// var

	// init
	function init() {
		$elm_button.on('click', function () {
			$('[name=form_name]').val('名前');
			$('[name=form_tel]').val('111');
			$('[name=form_mail]').val('test2@e-p-web.com');
			$('[name=form_mail_validate]').val('test2@e-p-web.com');
			$('[name=form_select]').val('項目2');
			$('[name=form_radio]').eq(2).prop('checked', true);
			$('[name="form_checkbox[]"]').eq(1).prop('checked', true);
			$('[name="form_checkbox[]"]').eq(2).prop('checked', true);
			$('[name=form_comment]').val('これはテストメールです。正式なお問い合わせではありません。');
		});
	}

	function func() {
		
	}

	init();

})();

/*******************************************
 *
 * zipcode button
 *
 ********************************************/

(function() {

	// element
	var $elm = $('#zip');

	// var

	// init
	function init() {
        // $elm.on('keyup',function(){
		// 	console.log(1);
		// 	AjaxZip3.zip2addr(
         //        'zip',
		// 		'',
		// 		'address',
		// 		'address'
		// 	);
		// });
	}

	init();

})();
/*******************************************
********************************************/
});