const $ = require('jquery');
import { gsap,Power2,Linear,TweenMax } from 'gsap';
import ScrollMagic from "scrollmagic";
import slick from 'slick-carousel';


$(function(){

'use strict';

/*******************************************
 * 
 * slider
 * 
 *******************************************/

(function() {

	// element
	var elm_slider = $('.brand-SectionProductDetail__slider');
	var elm_thum = $('.brand-SectionProductDetail__thums');

	// var
	
	// init
	function init() {
		setup();
	}

	//func
	function setup() {

		elm_slider.on('init', function(event, slick){
			
		});

		elm_slider.slick({
			dots: false,
			arrows: false,
			fade : true,

			autoplay : false,
			speed: 1000,
			pauseOnHover: false,
			pauseOnFocus: false,
			touchMove: false
		});
		
		elm_thum.find('._item').each(function(index,element){
			$(this).on('click',function(){
				elm_slider.slick('slickGoTo', index);
			});
		});

	}

	init();

})();

/*******************************************
 *******************************************/
});