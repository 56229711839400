
/*******************************************
 * 
 * animeScript
 * ページや要素ごとの具体的なアニメの記述
 * 
********************************************/

const $ = require('jquery');

'use strict';

export default function animeScript(){

var isDesktopWide = deviceObserver.isDesktopWide;
var isDesktop = deviceObserver.isDesktop;
var isTablet = deviceObserver.isTablet;
var isMobile = deviceObserver.isMobile;
var isMobileSE = deviceObserver.isMobileSE;

/*******************************************
 * 
 * example
 * 
********************************************/

if($('body.page-xxx').length){
	$('.Selector').each(function(index,element){
		$(this).find('.Element').each(function(index,element){
			if( isDesktopWide || isDesktop ){
				//wide-desktop

				//anime名
				$(this).attr('data-anime','fadeInUp');

				//trigger発火から開始までの秒数(s)
				$(this).attr('data-anime-delay', 0.5);

				//アニメーションの秒数(s)
				$(this).attr('data-anime-duration', 0.5);

				//scrollMagicのtrigger(selector)
				$(this).attr('data-anime-trigger', '.Section .Element');

				//triggerのy位置
				$(this).attr('data-anime-offset', '-100');
				
			}
			if( isTablet || isMobile || isMobileSE ){
				//tab-sp-spse
				//アニメ無しの時は記述しない
				
			}
		});
		$(this).find('.anotherElement').each(function(index,element){
			//
		});
		$(this).find('.anotherElementItems').each(function(index,element){
			$(this).find('.anotherElementItems__item').each(function(index,element){
				if( isDesktopWide || isDesktop || isTablet ){
					//連続で出現
					$(this).attr('data-anime','fadeInScaleUp');
					$(this).attr('data-anime-delay', index*0.3);
				}
			});
		});
	});
}

/*******************************************
 * 
 * top
 * 
********************************************/

if($('body.page-top').length){

	/* メインビジュアル */
	$('.top-MainVisual').each(function(index,element){
		$(this).find('.top-MainVisual__slider').each(function(index,element){
			if( isDesktopWide || isDesktop ){
				$(this).attr('data-anime','fadeInRight');
				$(this).attr('data-anime-delay', 1.5);
			}
		});
	});

	$('.top-MainVisualCover').each(function(index,element){
		$(this).find('.top-MainVisualCover__copy ._text1 ._l1').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInRight');
				$(this).attr('data-anime-trigger', '.top-MainVisualCover__copy');
                $(this).attr('data-anime-delay', 0.3);
			}
		});
		$(this).find('.top-MainVisualCover__copy ._text1 ._l2').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInRight');
                $(this).attr('data-anime-trigger', '.top-MainVisualCover__copy');
                $(this).attr('data-anime-delay', 0.6);
			}
		});
		$(this).find('.top-MainVisualCover__copy ._text2').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInUp');
				$(this).attr('data-anime-trigger', '.top-MainVisualCover__copy');
				$(this).attr('data-anime-delay', 0.9);
			}
		});
		$(this).find('.top-MainVisualCover__navi').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInUp');
				$(this).attr('data-anime-trigger', '.top-MainVisualCover__copy');
				$(this).attr('data-anime-delay', 1.2);
			}
		});
	});
}


/*******************************************
 *
 * その他
 *
 ********************************************/
if($('.animaFadeInUp').length){
	/* ページタイトル */
	var elem = '.animaFadeInUp';
	$(elem).each(function(){
		if( isDesktopWide || isDesktop ){
			$(this).attr('data-anime','fadeInUp');
			$(this).attr('data-anime-delay', 1.0);
		}
	})
}
if($('.PageTitle').length){
    /* ページタイトル */
    var elem = '.PageTitle .en';
	if( isDesktopWide || isDesktop ){
		$(elem).attr('data-anime','fadeInLeft');
		$(elem).attr('data-anime-trigger', '.PageTitle');
        $(elem).attr('data-anime-delay', 0.75);
	}
	var elem = '.PageTitle .ja';
	if( isDesktopWide || isDesktop ){
		$(elem).attr('data-anime','fadeInRight');
		$(elem).attr('data-anime-trigger', '.PageTitle');
        $(elem).attr('data-anime-delay', 1.0);
	}
}
if($('.top-BrandTile').length){
    /* トップブランド */
    $('.top-BrandTile').each(function(index,element){
        var parentIndex = index;
        $(this).find('.top-BrandTile__body ._inner > *').each(function(index,element){
			var anime = 'fadeInUp';
			if ($(this).hasClass('ButtonA')) {
				anime = 'fadeIn';
			}
			if ($(this).hasClass('_title2')) {
				anime = 'fadeInRight';
			}
            if( isDesktopWide || isDesktop ){
                $(this).attr('data-anime', anime);
                $(this).attr('data-anime-trigger', '.top-BrandTile[data-index="' + (parentIndex + 1) + '"]');
                $(this).attr('data-anime-delay', 1.75 + 0.3*index);
            } else {
                $(this).attr('data-anime', anime);
                $(this).attr('data-anime-trigger', '.top-BrandTile[data-index="' + (parentIndex + 1) + '"] .top-BrandTile__body');
                $(this).attr('data-anime-delay', 0.25 + 0.3*index);
			}
        });
        $(this).find('.top-BrandTile__bg').each(function(index,element){
            if( isDesktopWide || isDesktop ){
                $(this).attr('data-anime','addClass');
                $(this).attr('data-anime-trigger', '.top-BrandTile[data-index="' + (parentIndex + 1) + '"]');
                $(this).attr('data-anime-delay', 0);
            } else {
                $(this).attr('data-anime','addClass');
                $(this).attr('data-anime-trigger', '.top-BrandTile[data-index="' + (parentIndex + 1) + '"]');
                $(this).attr('data-anime-delay', 0);
			}
        });
        $(this).find('.top-BrandTile__photo').each(function(index,element){
            if( isDesktopWide || isDesktop ){
                $(this).attr('data-anime','fadeInRight');
                $(this).attr('data-anime-trigger', '.top-BrandTile[data-index="' + (parentIndex + 1) + '"]');
                $(this).attr('data-anime-delay', 1.25);
            } else {
                $(this).attr('data-anime','fadeInRight');
                $(this).attr('data-anime-trigger', '.top-BrandTile[data-index="' + (parentIndex + 1) + '"]');
                $(this).attr('data-anime-delay', 1.25);
			}
        });
    });
}
if($('body.page-brand').length){
    /* ブランドカバー */
	$('.brand-SectionCover').each(function(index,element){
		$(this).find('.brand-SectionCover__main').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInRight');
				$(this).attr('data-anime-trigger', '.brand-SectionCover__main');
			}
		});
		$(this).find('.brand-SectionCover__title').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInLeft');
				$(this).attr('data-anime-trigger', '.brand-SectionCover__main');
				$(this).attr('data-anime-delay', 0.3);
			}
		});
		$(this).find('.brand-SectionCover__body ._text1').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInRight');
				$(this).attr('data-anime-trigger', '.brand-SectionCover__main');
				$(this).attr('data-anime-delay', 0.6);
			}
		});
		$(this).find('.brand-SectionCover__body ._text2').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInRight');
				$(this).attr('data-anime-trigger', '.brand-SectionCover__main');
				$(this).attr('data-anime-delay', 0.9);
			}
		});
	});
    /* ブランド商品一覧 */
	$('.brand-ProductItems').each(function(index,element){
		$(this).find('.brand-ProductItems__item').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInUp');
				$(this).attr('data-anime-trigger', '.brand-ProductItems');
				$(this).attr('data-anime-delay', 0.2*index);
			}
		});
	});

	$('.brand-BrandCardItems').each(function(index,element){
		$(this).find('.brand-BrandCardItems__item').each(function(index,element){
			if( 1 ){
				$(this).attr('data-anime','fadeInUp');
				$(this).attr('data-anime-trigger', '.brand-BrandCardItems');
				$(this).attr('data-anime-delay', 0.2*index);
			}
		});
	});

}


if($('body.page-brand_detail').length){

	$('.brand-SectionProductDetail').each(function(index,element){
		$(this).find('.brand-SectionProductDetail__body').each(function(index,element){
			$(this).find('.brand-SectionProductDetail__heading ._brand').each(function(index,element){
				if( isDesktopWide || isDesktop ){
					$(this).attr('data-anime','fadeInRight');
					$(this).attr('data-anime-trigger', '.brand-SectionProductDetail');
					$(this).attr('data-anime-delay', 0.0);
				}
			});
            $(this).find('.brand-SectionProductDetail__heading ._name').each(function(index,element){
                if( isDesktopWide || isDesktop ){
                    $(this).attr('data-anime','fadeInRight');
                    $(this).attr('data-anime-trigger', '.brand-SectionProductDetail');
                    $(this).attr('data-anime-delay', 0.3);
                }
            });
			$(this).find('.brand-SectionProductDetail__heading ._title').each(function(index,element){
				if( isDesktopWide || isDesktop ){
					$(this).attr('data-anime','fadeInRight');
					$(this).attr('data-anime-trigger', '.brand-SectionProductDetail');
					$(this).attr('data-anime-delay', 0.6);
				}
			});
			$(this).find('.brand-SectionProductDetail__text').each(function(index,element){
				if( isDesktopWide || isDesktop ){
					$(this).attr('data-anime','fadeInRight');
					$(this).attr('data-anime-trigger', '.brand-SectionProductDetail');
					$(this).attr('data-anime-delay', 0.9);
				}
			});
			$(this).find('.brand-SectionProductDetail__info').each(function(index,element){
				if( isDesktopWide || isDesktop ){
					$(this).attr('data-anime','fadeInRight');
					$(this).attr('data-anime-trigger', '.brand-SectionProductDetail');
					$(this).attr('data-anime-delay', 1.2);
				}
			});
		});


	});

	$('.brand-ProductItems').each(function(index,element){
		$(this).find('.brand-ProductItems__item').each(function(index,element){
			if( isDesktopWide || isDesktop ){
				$(this).attr('data-anime','fadeInUp');
				$(this).attr('data-anime-trigger', '.brand-ProductItems');
				$(this).attr('data-anime-delay', 0.2*index);
			}
		});
	});

	$('.brand-BrandCardItems').each(function(index,element){
		$(this).find('.brand-BrandCardItems__item').each(function(index,element){
			if( isDesktopWide || isDesktop ){
				$(this).attr('data-anime','fadeInUp');
				$(this).attr('data-anime-trigger', '.brand-BrandCardItems');
				$(this).attr('data-anime-delay', 0.2*index);
			}
		});
	});

}

/*******************************************
********************************************/

}