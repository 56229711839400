const $ = require('jquery');
import { gsap,Power2,Linear,TweenMax } from 'gsap';
import ScrollMagic from "scrollmagic";
import slick from 'slick-carousel';


$(function(){

'use strict';
var isDesktopWide = deviceObserver.isDesktopWide;
var isDesktop = deviceObserver.isDesktop;
var isTablet = deviceObserver.isTablet;
var isMobile = deviceObserver.isMobile;
var isMobileSE = deviceObserver.isMobileSE;
/*******************************************
 * 
 * mv slider
 * 
 *******************************************/

(function() {

	// element
	var elm_slider = $('.top-MainVisual__slider');

	// var
	
	// init
	function init() {
		setup();
	}

	//func
	function setup() {

		elm_slider.on('init', function(event, slick){
		});

		elm_slider.slick({
			dots: false,
			arrows: false,
			fade : true,

			autoplay : true,
			speed: 1000,
			autoplaySpeed : 3000,
			pauseOnHover: false,
			pauseOnFocus: false,
			touchMove: true
		});

		elm_slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){

			var elm_beforeSlide = elm_slider.find('.top-MainVisual__sliderItem').eq(currentSlide);
			var elm_beforeSlideInner = elm_beforeSlide.find('._inner');
			var elm_beforeSlideImg = elm_beforeSlide.find('img');

			var elm_afterSlide = elm_slider.find('.top-MainVisual__sliderItem').eq(nextSlide);
			var elm_afterSlideInner = elm_afterSlide.find('._inner');
			var elm_afterSlideImg = elm_afterSlide.find('img');

			elm_slider.find('.slick-slide[data-slick-before]').removeAttr('data-slick-before');
			elm_slider.find('.slick-slide').eq(currentSlide).attr('data-slick-before','true');

			// 画像のアス比調整
            if( isDesktopWide || isDesktop ){
				var w = 1392;
				var h = 1888;
				var ratio = w/h;
				var ratio_after =  elm_beforeSlide.outerWidth() / elm_afterSlideImg.outerHeight();
				if (ratio < ratio_after) {
                    elm_afterSlideImg.css('height', elm_beforeSlide.outerWidth() / ratio + 'px')
				} else {
                    elm_afterSlideImg.css('height', '')
                }
            }

			// タイムライン
			var tl_before = gsap.timeline();
			tl_before
			.to( elm_beforeSlideImg, {scale: '1', duration: 0} )
			.pause()
			.to( elm_beforeSlideImg, {scale: '1.5', duration: 1.5, ease: Power2.easeInOut} )
			.to( elm_beforeSlideImg, {scale: '1', duration: 0} )

			var tl_after = gsap.timeline();
			tl_after
			.to( elm_afterSlideInner, {width: '0%', duration: 0} )
			.pause()
			.to( elm_afterSlideInner, {width: '100%', duration: 1, delay:0.5, ease: Power2.easeInOut} )



			// var tl_after2 = gsap.timeline();
			// tl_after2
			// .to( elm_afterSlideImg, {scale: '1.2', duration: 0} )
			// .pause()
			// .to( elm_afterSlideImg, {scale: '1', duration: 1.5} )

			tl_before.play();
			tl_after.play();
			//tl_after2.play();

		});
	}

	init();

})();


/*******************************************
 * 
 * bg size
 * 
 *******************************************/

(function() {

	// element
	var elm = $('.top-MainVisual__sliderSpacer');

	// var

	// init
	function init() {
        if( isDesktopWide || isDesktop ){
			sync_bgsize();
			$(window).on('resize',sync_bgsize);
        }
	}

	function sync_bgsize(){
		var w = elm.find('img').outerWidth() - 50;
		if (window.matchMedia('screen and (min-width: 1600px)')) {
            w = elm.find('img').outerWidth() - 100;
		}
		$('.top-BrandTile__bg').each(function(i){
			$(this).css('width', w+'px');
		})
	}

	init();

})();

/*******************************************
 *******************************************/
});