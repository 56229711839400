const $ = require('jquery');

$(function(){

'use strict';

/*******************************************
 * 
 *  deviceObserver
 * 
********************************************/

(function() {

	// constructor
	var DeviceObserver = function() {
		this.$window = $( window );
		this.$document = $( 'html, body' );

		this.windowInnerWidth = 0;
		this.offsetWidth = 0;

		this.isMobileSE = null;
		this.isMobile = null;
		this.isTablet = null;
		this.isDesktop = null;
		this.isDesktopWide = null;

		this.MobileSEQuery = 'screen and (max-width: 374px)';
		this.MobileQuery = 'screen and (min-width: 375px) and (max-width: 559px)';
		this.TabletQuery = 'screen and (min-width: 560px) and (max-width: 959px)';
		this.DesktopQuery = 'screen and (min-width: 960px) and (max-width: 1279px)';
		this.DesktopWideQuery = 'screen and (min-width: 1280px)';

		this.events = {
			Change: 'Change',

			MobileSE: 'MobileSE',
			Mobile: 'Mobile',
			Tablet: 'Tablet',
			Desktop: 'Desktop',
			DesktopWide: 'DesktopWide',

			DownToMobileSE: 'DownToMobileSE',
			DownToMobile: 'DownToMobile',
			DownToTablet: 'DownToTablet',
			DownToDesktop: 'DownToDesktop',
			DownToDesktopWide: 'DownToDesktopWide',

			UpToMobileSE: 'UpToMobileSE',
			UpToMobile: 'UpToMobile',
			UpToTablet: 'UpToTablet',
			UpToDesktop: 'UpToDesktop',
			UpToDesktopWide: 'UpToDesktopWide',
		};

		this.setup();
		//bind event
		window.addEventListener('resize', this.onResize.bind(this));
	};

	// member
	DeviceObserver.prototype.setup = function() {
		this.lastInnerWidth = window.innerWidth;
		this.isMobileSE = false;
		this.isMobile = false;
		this.isTablet = false;
		this.isDesktop = false;
		this.isDesktopWide = false;
		if(window.matchMedia( this.MobileSEQuery ).matches){
			this.isMobileSE = true;
		}else if(window.matchMedia( this.MobileQuery ).matches){
			this.isMobile = true;
		}else if(window.matchMedia( this.TabletQuery ).matches){
			this.isTablet = true;
		}else if(window.matchMedia( this.DesktopQuery ).matches){
			this.isDesktop = true;
		}else if(window.matchMedia( this.DesktopWideQuery ).matches){
			this.isDesktopWide = true;
		}
	};

	DeviceObserver.prototype.onResize = function() {

		//前回のwidthとの比較検出
		if(this.windowInnerWidth != window.innerWidth){
			this.offsetWidth = window.innerWidth - this.windowInnerWidth;
			this.windowInnerWidth = window.innerWidth;
		}
		
		if(window.matchMedia( this.MobileSEQuery ).matches){
			if(!this.isMobileSE){
				//this.isMobileSE = false;
				this.isMobile = false;
				this.isTablet = false;
				this.isDesktop = false;
				this.isDesktopWide = false;

				this.isMobileSE = true;
				this.$window.trigger(this.events.Change);
				this.$window.trigger(this.events.MobileSE);
				if( this.offsetWidth < 0 ){
					this.$window.trigger(this.events.DownToMobileSE);
				}
				if( this.offsetWidth > 0 ){
					this.$window.trigger(this.events.UpToMobileSE);
				}
			}
		}else if(window.matchMedia( this.MobileQuery ).matches){
			if(!this.isMobile){
				this.isMobileSE = false;
				//this.isMobile = false;
				this.isTablet = false;
				this.isDesktop = false;
				this.isDesktopWide = false;

				this.isMobile = true;
				this.$window.trigger( this.events.Change );
				this.$window.trigger( this.events.Mobile );
				if( this.offsetWidth < 0 ){
					this.$window.trigger( this.events.DownToMobile );
				}
				if( this.offsetWidth > 0 ){
					this.$window.trigger( this.events.UpToMobile );
				}
			}
		}else if(window.matchMedia( this.TabletQuery ).matches){
			if(!this.isTablet){
				this.isMobileSE = false;
				this.isMobile = false;
				//this.isTablet = false;
				this.isDesktop = false;
				this.isDesktopWide = false;
				
				this.isTablet = true;
				this.$window.trigger(this.events.Change);
				this.$window.trigger(this.events.Tablet);
				if( this.offsetWidth < 0 ){
					this.$window.trigger(this.events.DownToTablet);
				}
				if( this.offsetWidth > 0 ){
					this.$window.trigger(this.events.UpToTablet);
				}
			}
		}else if(window.matchMedia( this.DesktopQuery ).matches){
			if(!this.isDesktop){
				this.isMobileSE = false;
				this.isMobile = false;
				this.isTablet = false;
				//this.isDesktop = false;
				this.isDesktopWide = false;

				this.isDesktop = true;
				this.$window.trigger(this.events.Change);
				this.$window.trigger(this.events.Desktop);
				if( this.offsetWidth < 0 ){
					this.$window.trigger(this.events.DownToDesktop);
				}
				if( this.offsetWidth > 0 ){
					this.$window.trigger(this.events.UpToDesktop);
				}
			}
		}else if(window.matchMedia( this.DesktopWideQuery ).matches){
			if(!this.isDesktopWide){
				this.isMobileSE = false;
				this.isMobile = false;
				this.isTablet = false;
				this.isDesktop = false;
				//this.isDesktopWide = false;

				this.isDesktopWide = true;
				this.$window.trigger(this.events.Change);
				this.$window.trigger(this.events.DesktopWide);
				if( this.offsetWidth < 0 ){
					this.$window.trigger(this.events.DownToDesktopWide);
				}
				if( this.offsetWidth > 0 ){
					this.$window.trigger(this.events.UpToDesktopWide);
				}
			}
		}
	};

	// init
	window.deviceObserver = new DeviceObserver();

})();

/*******************************************
 * 
 *  add _blank to external link 
 * 
********************************************/

(function() {

	// Elements
	var $elm = $("a[href^='http://'],a[href^='https://']");

	// Vars
	var exarr = [
		"localhost",
		"192.168.",
		"127.0.0.1"
	];

	// init
	function init() {
		exarr.push(location.hostname);
		$elm.each(function(){
			if(typeof $(this).attr('target') != 'undefined') return;
			for(var i=0;i<exarr.length;++i){
				if(!$(this).attr('href').indexOf("http://"+exarr[i])) return;
				if(!$(this).attr('href').indexOf("https://"+exarr[i])) return;
			}
			$(this).attr('target','_blank');
		});
	}

	init();

})();

/*******************************************
 * 
 *  navibutton
 * 
********************************************/

(function() {

	// Elements
	var $elm_button = $('.header-NaviButton');
	var $elm_navi = $('.header-MenuPanel');
	var $elm_header = $('.header-Main');
	// Vars

	// init
	function init() {
		$elm_button.on('click', function(){
			$(this).toggleClass('is-active');
			if($(this).hasClass('is-active')){
				//$elm_navi.fadeIn(300);
				$elm_header.addClass('is-navi_opened');
				$('body').addClass('is-navi_opened');
			}else{
				//$elm_navi.fadeOut(300);
				$elm_header.removeClass('is-navi_opened');
				$('body').removeClass('is-navi_opened');
			}
		});
        $(document).on('click',function(e) {
            if ($('body').hasClass('is-navi_opened')) {
            	console.log($(e.currentTarget));
                if(!$(e.target).closest('.header-MenuPanel__inner').length && !($(e.target).hasClass('header-NaviButton') || $(e.target).closest('header-NaviButton').length)) {
                    $elm_button.removeClass('is-active');
                    $elm_header.removeClass('is-navi_opened');
                    $('body').removeClass('is-navi_opened');
                }
            }
        });
    }

	init();

})();


/*******************************************
 * 
 *  scroll link
 * 
********************************************/

(function() {

	// Elements
	var $elm = $("a[data-type=scroll]");
	var $elm_button = $('.header-NaviButton');
	var $elm_navi = $('.header-MainMenu');
	var $elm_header = $('.header-Main');

	// Vars
	var $speed = 400;

	// init
	function init() {
		$elm.each(function(){
			$(this).on('click', function(){
				var $href = $(this).attr("href");
				var $index = $href.indexOf("#");
				var $target = $($href.slice($index));
				if($target.length){
					var position = $target.offset().top;
					$('body,html').animate({scrollTop:position}, $speed, 'swing');
					if($elm_button.hasClass('is-active')){
						$elm_navi.fadeOut(300);
						$elm_header.removeClass('is-navi_opened');
						$('html,body').removeClass('is-navi_opened');
					}
					return false;
				}
			});
		});
	}

	init();

})();


/*******************************************
 * 
 *  scrolling
 * 
********************************************/

(function() {

	// Elements
	var $elm_header = $('.header-Main');
	var is_top = 10;
	is_top = $('.page-top').length ? $(window).innerHeight(): is_top;
	//var $elm_upbutton = $('.MainUpButton');
	
	// Vars

	// init
	function init() {
		scrolling();
		$(window).scroll(scrolling);
	}

	function scrolling(){
		var scrollTop = $(window).scrollTop();
		var windowHeight = $(window).innerHeight();
		var documentHeight = $(document).innerHeight();
		
		if( scrollTop < is_top ){
			//top
			$elm_header.addClass('is-top');
			$elm_header.removeClass('is-middle');
			$elm_header.removeClass('is-bottom');
		}else if(scrollTop > (documentHeight - windowHeight - 300) ){
			//bottom
			$elm_header.removeClass('is-top');
			$elm_header.removeClass('is-middle');
			$elm_header.addClass('is-bottom');
		}else{
			//middle
			$elm_header.removeClass('is-top');
			$elm_header.addClass('is-middle');
			$elm_header.removeClass('is-bottom');
		}

		// if(scrollTop>200){
		// 	//under
		// 	if(!$elm_upbutton.hasClass('is-active')){
		// 		$elm_upbutton.addClass('is-active');
		// 	}
		// }else{
		// 	//upside
		// 	if($elm_upbutton.hasClass('is-active')){
		// 		$elm_upbutton.removeClass('is-active');
		// 	}
		// }
	}

	init();

})();

/*******************************************
 * 
 *  upperarea button
 * 
********************************************/

(function() {

	// Elements
	var elm_canvas = $('.footer-Main__canvas canvas')[0];

	// Vars

	// init
	function init() {
		var canvasWidth = 490;
		var canvasHeighth = 230;
		elm_canvas.width = canvasWidth;
		elm_canvas.height = canvasHeighth;

		var context = elm_canvas.getContext('2d');


		context.beginPath();
		context.strokeStyle = 'rgb(255,255,255)';


		context.moveTo(0,canvasHeighth/2);
		context.lineTo(220,canvasHeighth/2);

		//真ん中を通すには230(canvasHeighth)/2 = 115を通る
		for(var i=0;i<7;++i){
			context.moveTo(220,canvasHeighth/2);
			context.lineTo(300,10+35*i);
			context.lineTo(canvasWidth,10+35*i);
		}
		

		// context.stroke();
	}

	init();

})();

/*******************************************
********************************************/
});