const $ = require('jquery');
import { gsap,Power2,Linear } from 'gsap';
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import animeScript from './animeScript.js';

$(function(){

'use strict';

/*******************************************
 * 
 * anime classes
 * 
********************************************/

(function() {

	// element

	// var
	var animeController;
	var tlObjects;
	var triggerHock = 0.9;
    var isMobile = deviceObserver.isMobile;
	
	// init
	function init() {
		tlObjects = new Array();

		deviceObserver.$window.on( deviceObserver.events.Change, function(){
			resetAnimeObjects();
			animeController.destroy();

			animeController = new ScrollMagic.Controller();
			animeScript();
			setup();
		});

		animeController = new ScrollMagic.Controller();
		animeScript();
		setup();
	}

	//動作の設定
	function setup(){
        if( isMobile ){
        	triggerHock = 1.0;
        }
		$('[data-anime=fadeIn]').each(function(){
			setAnimation(
				this, //elm
				animeController, //controller
				{} //tween
			);
		});

		$('[data-anime=fadeInLeft]').each(function(){
			//data-anime-value
			var value = $(this).attr('data-anime-value');
			if(value==null) value = 50;

			setAnimation(
				this, //elm
				animeController, //controller
				{ x: '-='+value } //tween
			);
		});

		$('[data-anime=fadeInRight]').each(function(){
			//data-anime-value
			var value = $(this).attr('data-anime-value');
			if(value==null) value = 50;

			setAnimation(
				this, //elm
				animeController, //controller
				{ x: '+='+value } //tween
			);
		});

		$('[data-anime=fadeInUp]').each(function(){
			//data-anime-value
			var value = $(this).attr('data-anime-value');
			if(value==null) value = 50;

			setAnimation(
				this, //elm
				animeController, //controller
				{ y: '+='+value } //tween
			);
		});

		$('[data-anime=fadeInDown]').each(function(){
			//data-anime-value
			var value = $(this).attr('data-anime-value');
			if(value==null) value = 50;

			setAnimation(
				this, //elm
				animeController, //controller
				{ y: '-='+value } //tween
			);
		});

		$('[data-anime=fadeInScaleUp]').each(function(){
			//data-anime-value
			var value = $(this).attr('data-anime-value');
			if(value==null) value = 0.8;

			setAnimation(
				this, //elm
				animeController, //controller
				{ scale: value } //tween
			);
		});

		$('[data-anime=fadeInScaleDown]').each(function(){
			//data-anime-value
			var value = $(this).attr('data-anime-value');
			if(value==null) value = 1.2;

			setAnimation(
				this, //elm
				animeController, //controller
				{ scale: value } //tween
			);
		});

		$('[data-anime=addClass]').each(function(){
			//data-anime-delay
			var delay = $(this).attr('data-anime-delay');
			if(!delay) delay = 0;
			delay -= 0;

			//data-anime-trigger
			var trigger = $(this).attr('data-anime-trigger');
			if(!trigger) trigger = this;

			//data-anime-offset
			var offset = $(this).attr('data-anime-offset');
			if(!offset) offset = -200;
			
			var $elm = $(this);
			var scene = new ScrollMagic.Scene({
				'triggerElement': trigger,
				'offset': offset,
				'reverse': false
			})
			.on('enter',function(){
				if(!$elm.hasClass('is-animated')){
					setTimeout(function(){
						$elm.addClass('is-animated');
					},delay*1000);
				}
			});
			scene.addTo(animeController)
		});
	}


	//全ての要素のdata-animeを削除
	function resetAnimeObjects(){
		$('[data-anime]').removeAttr('data-anime');
		$('[data-anime-delay]').removeAttr('data-anime-delay');
		$('[data-anime-duration]').removeAttr('data-anime-duration');
		$('[data-anime-trigger]').removeAttr('data-anime-trigger');
		$('[data-anime-offset]').removeAttr('data-anime-offset');
		$('[data-anime-value]').removeAttr('data-anime-value');
		$('.is-animated').removeClass('is-animated');

		//アニメーションを最後まで進める
		for(var i=0;tlObjects.length>i;++i){
			tlObjects[i].pause(tlObjects[i].endTime());
			tlObjects[i].kill();
		}
		
	}


	function setAnimation(_elm, _controller, _tween) {
		//data-anime-delay
		var delay = $(_elm).attr('data-anime-delay');
		if(delay==null) delay = 0;
		delay -= 0;

		//data-anime-trigger
		var trigger = $(_elm).attr('data-anime-trigger');
		if(trigger==null) trigger = _elm;

		//data-anime-offset
		var offset = $(_elm).attr('data-anime-offset');
		if(offset==null) offset = 0;
		offset -= 0;
		
		//data-anime-offset
		var duration = $(_elm).attr('data-anime-duration');
		if(duration==null) duration = 1.0;
		duration -= 0;

		//before tween
		_tween.autoAlpha = 0;

		var toTween = {
			autoAlpha: 1,
			// ease: Power2.easeOut
			ease: "power4"
		}

		//変化のある値を0に戻す
		toTween.autoAlpha = 1;
		if(_tween.scale) toTween.scale = 1;
		if(_tween.x) toTween.x = 0;
		if(_tween.y) toTween.y = 0;

		var tl = gsap.timeline()

		//.to(_elm, 0, {autoAlpha: 0})

		tl.to(_elm, 0, {autoAlpha: 0})
		.delay(delay)
		.pause()
		.to(_elm, 0, _tween)
		.to(_elm, duration, toTween)

		var scene = new ScrollMagic.Scene({
			'triggerElement': trigger,
			'triggerHook': triggerHock, // percentage
			// 'triggerHook': 'onEnter', // percentage
			'offset': offset,
			'duration': duration,
			'reverse': false,
		})
		.on('enter',function(){
			tl.play()
			$(_elm).addClass('finished');
		});

		tlObjects.push(tl);

		// scene.addIndicators()
		scene.addTo(_controller)
	}

	init();

})();

/*******************************************
********************************************/
});